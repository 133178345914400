.omessageSider .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #f5f5f5;
}
.SideBarBtns {
  width: 10rem;
  height: 2.8rem;
  background-color: #009efd;
  border-radius: 4px;
  font-size: 0.7rem;
  bottom: 80px;
}
.defaultHeaderStyle {
  color: #334d6e;
  margin-left: 15px;
  margin-top: 10px;
}
.InboxSelect {
  width: 20rem;
  border-color: #009efd;
}
.InboxSelect .ant-select-selector {
  height: 3rem !important;
  border-radius: 1px !important;
}
.InboxMenu.ant-menu-horizontal {
  border-bottom: 0px;
}
.InboxMenu.ant-menu-light {
  background-color: transparent;
}
.ant-divider-horizontal {
  margin: 10px 0px;
}
.inboxTable thead .ant-table-cell {
  background-color: #009efd !important;
  color: white !important;
}
.inboxTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.inboxTable.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: center;
}
.inboxTable .ant-table-tbody > tr > td {
  background-color: #f8f8f8;
  text-align: center;
}
.inboxTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #ebf4f8;
}

.selectServices.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  color: #009efd;
  border-color: #009efd;
  width: 180px;
  border-radius: 1px;
}
.selectServices .ant-select-arrow {
  color: #009efd;
}
.selectServices.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 42px;
}
.nextBtnPhone {
  color: white;
  background-color: #009efd;
  height: 2.5rem;
  width: 10rem;
  border-radius: 4px;
}
.sprite {
  background-image: url("../images/icons.png") 100 120;
  background-repeat: no-repeat;
}
.sprite-icon {
  width: 20px;
  height: 27px;
  background-repeat: no-repeat;
  background: url("../images/helloSendIcons.png");
  background-size: 260px 133px;
  transform: translate(0%, 0%) scale(4.5);
}
.icon-logo {
  background-position: -27.5px -68px;
}
.sprites {
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  background: url("../images/icons.png");
  background-size: 162px 82px;
  transform: translate(0%, 0%) scale(2.5);
  border-radius: 37px;
}
.icon-1 {
  background-position: 0.33px -19.9px;
}
.icon-2 {
  background-position: -19px -19.9px;
}
.icon-3 {
  background-position: -38px -19.9px;
}
.icon-4 {
  background-position: -57.5px -19.9px;
}
.icon-5 {
  background-position: -76.5px -19.9px;
}
.icon-6 {
  background-position: -95.8px -19.9px;
}
.icon-7 {
  background-position: -115px -19.9px;
}
.icon-8 {
  background-position: -339px -33.4px;
  margin-top: -4px;
  margin-right: 8px;
}
.WorkFlowIcon {
  background-position: 33.15% 34.1%;
  border-radius: 37px;
  margin-left: 8px;
}

.icon-9 {
  background-position: -153.5px -19.9px;
}
.icon-10 {
  background-position: -232px -19.9px;
}
.icon-11 {
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background: url("../images/icons.png");
  background-size: 198px 187px;
  transform: translate(0%, 0%) scale(3.5);
  background-position: -17px -617px;
}

.campaignIcon {
  background-position: 35.8% -0.3%;
  border-radius: 37px;
  /* padding: 10px;
  padding-left: 22px; */
  /* margin-right: 10px; */
}

.inboxIconBlue {
  background-position: 49.5% 1.4%;
  border-radius: 37px;
}

.overviewIcon {
  background-position: 36.4% 0.6%;
  border-radius: 37px;
}
.hellosendLogo {
  background-position: 0rem -36.55rem;
  width: 132px !important;
  height: 50px !important;
  transform: translate(1%, 0%) scale(1.1);
}
.overviewIconTopbar {
  background-position: 46.2% 1.1%;
  border-radius: 37px;
}

.templatesIcon {
  background-position: 40.8% -0.4%;
  border-radius: 37px;
}

.automationIcon {
  background-position: 8.7% 33.9%;
  border-radius: 37px;
}
.moreIcon {
  background-position: 25% -0.1%;
  border-radius: 37px;
}
.channelsIcon {
  background-position: 30.5% -0.1%;
  border-radius: 37px;
}
.configurePhoneSettingsIcon {
  background-position: 45.5% 75.9%;
  border-radius: 37px;
}
.emptyAutomationListView {
  width: 50px !important;
  height: 50px !important;
  background-position: 43.5% 55.9%;
  border-radius: 37px;
}
.settingIcon {
  height: 30px !important;
  background-position: 35.8% 4.8%;
  border-radius: 37px;
}
.sendSmsSettingIcon {
  height: 30px !important;
  background-position: 35.8% 4.66%;
  border-radius: 37px;
}
.inboxIcon {
  border-radius: 37px;
  background-position: 51.8% 0.4%;
}
.inboxIconTopbar {
  border-radius: 37px;
  background-position: 51.5% 1%;
  transform: translate(2%, 0%) scale(0.9);
}
.usersIcon {
  border-radius: 37px;
  background-position: 56.4% 0.7%;
}
.settingsIcon {
  border-radius: 37px;
  background-position: 35.8% 5%;
}
.helpIcon {
  background-position: -14.4rem -2rem;
  border-radius: 37px;
  height: 30px !important;
}
.bookAgentIcon {
  background-position: -23.1rem -28.5rem;
  border-radius: 37px;
  height: 30px !important;
}
.selectedSidebarItem {
  outline: 1px solid #e6e7ec; /* Adjust the color and width as needed */
  background-color: white !important; /* Optional: Add a background color */
  width: 170px !important;
  box-shadow: 0 2px 2px #e2e5ec !important;
  padding-bottom: 1px;
}
.topBarArrow {
  border-radius: 37px;
  background-position: 70.8% 32.5%;
}
.topBarHellosend {
  background-position: -27.5px -68px;
  transform: translate(0%, 8%) scale(2.5);
}
.channels-helloSend-icon {
  background-position: -26.7px -68px;
  transform: translate(0%, 10%) scale(2.8);
}

.topBarTeams {
  background-position: 46.3% 70%;
  background-size: 400px 400px;
}
.sidebarHoverWidth {
  width: 165px;
}
.sideBarMoreHoverBackground {
  margin-bottom: 100px;
  margin-top: 10px;
}
.sideBarMoreHover {
  padding-bottom: 10px;
}
.sideBarHoverBackground:hover {
  background-color: #f5f5f5;
}
.sideBarMoreHoverBackground.ant-menu-submenu:hover {
  background-color: #f5f5f5 !important;
}
.icon-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}
.triggerIcon {
  position: absolute;
  left: 5px;
  bottom: 0;
  z-index: -1;
}
.omessageSider.ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06) !important;
}
.omessageSider .ant-layout-sider-trigger {
  width: 4rem !important;
}
.omessageSider .ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0px;
}
.omessageSider {
  border-inline-end: 1px solid rgb(230, 231, 236) !important;
}
.commonGhostButton {
  color: var(--hs-color-BlackRussian) !important;
}
.commonGhostButton:hover {
  border-color: var(--hs-violet) !important;
}
.omessageSider .ant-menu-submenu-title {
  color: black;
  height: 60px !important;
  width: 180px !important;
}
.omessageSider .ant-menu-submenu-title:hover {
  height: 60px !important;
  background-color: #f5f5f5 !important;
  width: 180px !important;
}
.omessageSider .ant-menu-submenu ul .analyticsText {
  margin-left: 10px; /* Space between icon and text, adjust as needed */
  text-align: center; /* Ensuring text is centered */
  margin-right: 55px;
  color: black !important;
  padding: 10px;
  padding-left: 20px;
}
.omessageSider .ant-menu-submenu ul {
  background-color: #ffffff !important;
}

.sideBarBottomMenu .ant-row-middle {
  margin-top: 12px !important;
}
.analyticsMoreText {
  margin-left: 8px; /* Space between icon and text, adjust as needed */
  text-align: center; /* Ensuring text is centered */
  margin-right: 55px;
  color: black !important;
}

.inboxText {
  margin-left: 10px; /* Space between icon and text, adjust as needed */
  text-align: left; /* Ensuring text is centered */
  margin-right: 160px;
  color: black !important;
}

.sideBarMoreHoverBackground .ant-menu-submenu-title {
  padding-inline: calc(11% - 8px - 4px) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: white;
}

.ant-menu-submenu-active .ant-menu-submenu-selected :hover {
  background-color: white !important;
}
.sendSMSTopBarWidth ul {
  width: 180px;
}
.settingsMenuListClass {
  /* padding-inline: 0px !important; */
  position: relative;
  left: -10px;
  border-inline-end: 0 !important;
}
.custom-menu .ant-menu-item-selected {
  background-color: inherit !important;
  color: inherit !important;
}

/* If you also want to remove the hover effect */
/* .custom-menu .ant-menu-item:hover,
.custom-menu .ant-menu-submenu-title:hover {
  background-color: inherit !important;
  color: inherit !important;
} */
.settingsMenuListClass li {
  /* height: 40px !important; */
  padding-left: 5px !important;
}
.settingsMenuListClass li div {
  height: 40px !important;
}

.successTickIcon {
  background-position: -19rem -17.5rem;
  border-radius: 37px;
}
.phoneNumberScheduleIcon {
  background-position: -0.8rem -22.1rem;
  border-radius: 37px;
}
.permissionDeniedIcon {
  background-position: -14.25rem -17.5rem;
  border-radius: 37px;
}
.omessageSider .ant-menu-item {
  height: 60px;
  width: 180px !important;
}
.omessageSider .ant-menu-item.ant-menu-item-selected,
.omessageSider .ant-menu-submenu-selected .ant-menu-submenu-title[aria-expanded="true"] {
  background-color: white !important;
  border-radius: 12px !important;
  color: transparent;
  /* box-shadow: 0 6px 29px #e2e5ec, 0 0.75px 3.63px #e2e5ec !important; */
  border: 1px solid var(--hs-container-outline-grey) !important;
  filter: drop-shadow(0px 0.75px 3.63px rgba(89, 108, 148, 0.035)) drop-shadow(0px 6px 29px rgba(89, 108, 148, 0.07));
}
.omessageSider .ant-menu-submenu [data-menu-id*="workflow"] {
  padding-left: 55px !important;
}
.omessageSider .ant-menu-submenu [data-menu-id*="automation"] {
  padding-left: 51px !important;
}
@media (max-width: 768px) {
  .sendSmsSideBar .ant-menu-item[data-menu-id*="campaign"] {
    padding-left: 38px !important;
  }
  .sendSmsSideBar .ant-menu-item[data-menu-id*="workflow"] {
    padding-left: 40px !important;
  }
  .sendSmsSideBar .ant-menu-item[data-menu-id*="teams"] {
    padding-left: 40px !important;
  }
  .sendSmsSideBar .ant-menu-item[data-menu-id*="history"] {
    padding-left: 40px !important;
  }
}

.ant-menu-item-selected .interFontWeightMedium,
.ant-menu-submenu-selected .interFontWeightMedium {
  color: black !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-arrow {
  color: black;
}

.omessageSider .ant-menu-submenu .ant-menu-item-selected {
  color: black !important;
  background-color: var(--hs-light-violet) !important;
  outline: var(--hs-light-violet) !important;
  border-radius: 12px;
  border: none !important;
  left: 3px;
}
.sendSmsSideBar .ant-menu-submenu .ant-menu-item-selected {
  color: black !important;
  background-color: var(--hs-light-violet) !important;
  outline: var(--hs-light-violet) !important;
  border-radius: 12px;
  border: none !important;
  left: 3px;
}

.display-inline-block {
  display: block;
}

.supportBook {
  background-color: #f5f6f8;
  border: 1px solid #e6e7ec;
}

.right-arrowSidebar {
  background-position: 78% 19%;
}

.disabled-look {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}
.automationTable .ant-table-cell {
  cursor: text;
}
.removeOutlineAntDrawer .ant-drawer-body {
  padding: 0px !important;
}
.voice-side-bar-icon {
  background-position: 8.5% 96%;
}
